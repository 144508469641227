import React, { useMemo } from "react";
import "./ModalSwiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
const ModalSwiper = ({ images = [], onModalClose, currentSlideIndex }) => {
  const gallerySlidesContent = useMemo(() =>
    images.map(
      (img, index) => (
        <SwiperSlide key={index}>
          <img src={img} />
        </SwiperSlide>
      ),
      [images]
    )
  );
  return (
    <div className="modal">
      <p className="close" onClick={onModalClose}>
        X
      </p>
      <div className="modal-s">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          navigation={true}
          modules={[Navigation]}
          loop={true}
          initialSlide={currentSlideIndex}
        >
          {gallerySlidesContent}
        </Swiper>
      </div>
    </div>
  );
};
export default ModalSwiper;
