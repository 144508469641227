import React, { useState,useEffect } from 'react';
import '../Recorrido/Recorrido.scss'
import View360 from '../View360/View360';

const RecorridoHome = () => {
    const [idScene, setId] = useState("cancha");
    const [yaw, setyaw] = useState(50)
    const [pitch, setpitch] = useState(0)

    const handleImagen = (tipo) =>{
        const removeElement = document.getElementsByClassName("active");
        removeElement[0].classList.remove("active")
        const element = document.getElementById(tipo);
        element.classList.add("active");
        if(tipo==="cancha"){
            setId("cancha")
            setyaw(50)
            setpitch(0)
        }else{
            if(tipo==="juegos"){
                setyaw(-30)
                setpitch(0)
                setId("juegos")
            }
        }
    }
    return ( 
        <div className='contenedor_recorrido'>
            {
               idScene ==="cancha" &&
               <View360 yaw={yaw} pitch={pitch} urlImagen="/assets/Artemisa/img-360-padel-velasur.jpg" idScene="cancha"></View360>
            }
            {
                idScene ==="juegos" &&
                <View360 yaw={yaw} pitch={pitch} urlImagen="/assets/Artemisa/img-360-juegos-infantiles-velasur.jpg" idScene="juegos"></View360>
            }
            <span className='disclamer'>Esta imagen es representativa del proyecto, sus características pueden cambiar sin previo aviso.</span>
            <div className='options'>
                <button id="cancha" className="active" onClick={()=>{handleImagen("cancha")}}>CANCHA PADEL</button>
                <button id="juegos" onClick={()=>{handleImagen("juegos")}}>JUEGOS</button>
            </div>
        </div>
     );
}
 
export default RecorridoHome;