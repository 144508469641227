import React, { useMemo } from "react";
import "./PrototypeDetail.scss";

const PrototypeDetail = ({ floorType, surface, features, floorImage }) => {
  const featuresList = useMemo(
    () => (
      <ul>
        {features.map(
          (feat, index) => (
            <li key={index} className="characteristics">
              <img
                className="img"
                src="/assets/casas-velasur/svg-iso-velasur.svg"
                alt=""
              />
              <div>
                <span>{feat.characteristics}</span>
                {feat.additional && (
                  <>
                    <br />
                    <span className="additional">{feat.additional}</span>
                  </>
                )}
              </div>
            </li>
          ),
          [features]
        )}
      </ul>
    ),
    [features]
  );

  return (
    <div className="container-prototypes">
      <div className="container-secundary-prototypes">
        <div className="contenedor-title-main">
          <div className="contenedor-title">
            <p className="title">{floorType}</p>
          </div>
        </div>
        <div className="bottom_list">{featuresList}</div>
      </div>
      <div className="container-image">
        <img alt="prototypeFloorImage" src={floorImage} />
      </div>
    </div>
  );
};

export default PrototypeDetail;
