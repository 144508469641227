import React from 'react';
import './Video.scss'
const Video = (props) => {
    return ( 
        <div className='contenedor_modal_video'>
            <p onClick={()=>{props.setVideo(false)}} className='closed'>X</p>
            <iframe src={`https://www.youtube.com/embed/${props.urlVideo}?autoplay=1&origin=https://velasur.mx`} ></iframe>
        </div>
     );
}
 
export default Video;