import React from "react";
import Amenidades from '../Amenidades/Amenidades'
import AmenidadesDesc from '../AmenidadesDesc/AmenidadesDesc'
import Recorrido from "../Recorrido/Recorrido";
import HomeAcacia from "./HomeAcacia";
const PrivadaAcacia = () => {
return(
    <>
    <HomeAcacia/>
    <Recorrido/>
    <Amenidades/>
    <AmenidadesDesc/>
    </>
);
}
export default PrivadaAcacia;