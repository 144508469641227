import React, { useState } from "react";
import "./PrototypeContainer.scss";
import PrototypeDetail from "../Casas-Velasur/PrototypeDetail/PrototypeDetail";
import PrototypeCarousel from "../PrototypeCarousel/PrototypeCarousel";
import {
  FloorType,
  PrototypeTypes,
  getPrototype,
} from "../../utils/prototypes";
import { CSSTransition } from "react-transition-group";
import FormPrototype from "../Modal/FormPrototype";

const PrototypeContainer = ({ setModalConfigProps }) => {
  const [showModal, setModal] = useState(false);
  const [namePrototype, setNamePrototype] = useState("");
  const AcaciaI = getPrototype(PrototypeTypes.AcaciaI);
  const AcaciaIII = getPrototype(PrototypeTypes.AcaciaIII);
  const AcaciaIV = getPrototype(PrototypeTypes.AcaciaIV);
  const AcaciaXIX = getPrototype(PrototypeTypes.AcaciaXIX);
  const AcaciaXXI = getPrototype(PrototypeTypes.AcaciaXXI);
  const AcaciaCVII = getPrototype(PrototypeTypes.AcaciaCVII);

  const handlePrototype = (name) => {
    setNamePrototype(name);
    setModal(true);
  };
  return (
    <>
      <CSSTransition
        in={showModal}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <FormPrototype setModal={setModal} prototype={namePrototype} />
      </CSSTransition>

      <div className="prototype-container">
        <img
          className="iso"
          src="/assets/casas-velasur/svg-iso-velasur.svg"
          alt=""
        />
        <h2>{AcaciaI.name}</h2>
        <div className="text-ground">
          <p>
            Terreno: {AcaciaI.terreno} m<sup>2</sup>
          </p>
          <p>
            Construcción: {AcaciaI.construccion} m<sup>2</sup>
          </p>
        </div>
        <div className="prototype-container-detail">
          <div className="container-carousel">
            <PrototypeCarousel
              images={AcaciaI.gallery}
              setModalConfigProps={setModalConfigProps}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaI.floor[FloorType.LowFloor].title}
              surface={AcaciaI.floor[FloorType.LowFloor].surface}
              features={AcaciaI.floor[FloorType.LowFloor].features}
              floorImage={AcaciaI.floor[FloorType.LowFloor].floorImage}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaI.floor[FloorType.TopFloor].title}
              surface={AcaciaI.floor[FloorType.TopFloor].surface}
              features={AcaciaI.floor[FloorType.TopFloor].features}
              floorImage={AcaciaI.floor[FloorType.TopFloor].floorImage}
            />
          </div>
        </div>
        <button onClick={() => handlePrototype(AcaciaI.name)}>
          ESTOY INTERESADO
        </button>
      </div>
      <div className="prototype-container brown">
        <img
          className="iso"
          src="/assets/casas-velasur/svg-iso-velasur.svg"
          alt=""
        />
        <h2>{AcaciaIII.name}</h2>
        <div className="text-ground">
          <p>
            Terreno: {AcaciaIII.terreno} m<sup>2</sup>
          </p>
          <p>
            Construcción: {AcaciaIII.construccion} m<sup>2</sup>
          </p>
        </div>
        <div className="prototype-container-detail">
          <div className="container-carousel">
            <PrototypeCarousel
              images={AcaciaIII.gallery}
              setModalConfigProps={setModalConfigProps}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaIII.floor[FloorType.LowFloor].title}
              surface={AcaciaIII.floor[FloorType.LowFloor].surface}
              features={AcaciaIII.floor[FloorType.LowFloor].features}
              floorImage={AcaciaIII.floor[FloorType.LowFloor].floorImage}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaIII.floor[FloorType.TopFloor].title}
              surface={AcaciaIII.floor[FloorType.TopFloor].surface}
              features={AcaciaIII.floor[FloorType.TopFloor].features}
              floorImage={AcaciaIII.floor[FloorType.TopFloor].floorImage}
            />
          </div>
        </div>
        <button onClick={() => handlePrototype(AcaciaIII.name)}>
          ESTOY INTERESADO
        </button>
      </div>
      <div className="prototype-container">
        <img
          className="iso"
          src="/assets/casas-velasur/svg-iso-velasur.svg"
          alt=""
        />
        <h2>{AcaciaIV.name}</h2>
        <div className="text-ground">
          <p>
            Terreno: {AcaciaIV.terreno} m<sup>2</sup>
          </p>
          <p>
            Construcción: {AcaciaIV.construccion} m<sup>2</sup>
          </p>
        </div>
        <div className="prototype-container-detail">
          <div className="container-carousel">
            <PrototypeCarousel
              images={AcaciaIV.gallery}
              setModalConfigProps={setModalConfigProps}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaIV.floor[FloorType.LowFloor].title}
              surface={AcaciaIV.floor[FloorType.LowFloor].surface}
              features={AcaciaIV.floor[FloorType.LowFloor].features}
              floorImage={AcaciaIV.floor[FloorType.LowFloor].floorImage}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaIV.floor[FloorType.TopFloor].title}
              surface={AcaciaIV.floor[FloorType.TopFloor].surface}
              features={AcaciaIV.floor[FloorType.TopFloor].features}
              floorImage={AcaciaIV.floor[FloorType.TopFloor].floorImage}
            />
          </div>
        </div>
        <button onClick={() => handlePrototype(AcaciaIV.name)}>
          ESTOY INTERESADO
        </button>
      </div>
      <div className="prototype-container brown">
        <img
          className="iso"
          src="/assets/casas-velasur/svg-iso-velasur.svg"
          alt=""
        />
        <h2>{AcaciaXIX.name}</h2>
        <div className="text-ground">
          <p>
            Terreno: {AcaciaXIX.terreno} m<sup>2</sup>
          </p>
          <p>
            Construcción: {AcaciaXIX.construccion} m<sup>2</sup>
          </p>
        </div>
        <div className="prototype-container-detail">
          <div className="container-carousel">
            <PrototypeCarousel
              images={AcaciaXIX.gallery}
              setModalConfigProps={setModalConfigProps}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaXIX.floor[FloorType.LowFloor].title}
              surface={AcaciaXIX.floor[FloorType.LowFloor].surface}
              features={AcaciaXIX.floor[FloorType.LowFloor].features}
              floorImage={AcaciaXIX.floor[FloorType.LowFloor].floorImage}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaXIX.floor[FloorType.TopFloor].title}
              surface={AcaciaXIX.floor[FloorType.TopFloor].surface}
              features={AcaciaXIX.floor[FloorType.TopFloor].features}
              floorImage={AcaciaXIX.floor[FloorType.TopFloor].floorImage}
            />
          </div>
        </div>
        <button onClick={() => handlePrototype(AcaciaXIX.name)}>
          ESTOY INTERESADO
        </button>
      </div>
      <div className="prototype-container">
        <img
          className="iso"
          src="/assets/casas-velasur/svg-iso-velasur.svg"
          alt=""
        />
        <h2>{AcaciaXXI.name}</h2>
        <div className="text-ground">
          <p>
            Terreno: {AcaciaXXI.terreno} m<sup>2</sup>
          </p>
          <p>
            Construcción: {AcaciaXXI.construccion} m<sup>2</sup>
          </p>
        </div>
        <div className="prototype-container-detail">
          <div className="container-carousel">
            <PrototypeCarousel
              images={AcaciaXXI.gallery}
              setModalConfigProps={setModalConfigProps}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaXXI.floor[FloorType.LowFloor].title}
              surface={AcaciaXXI.floor[FloorType.LowFloor].surface}
              features={AcaciaXXI.floor[FloorType.LowFloor].features}
              floorImage={AcaciaXXI.floor[FloorType.LowFloor].floorImage}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaXXI.floor[FloorType.TopFloor].title}
              surface={AcaciaXXI.floor[FloorType.TopFloor].surface}
              features={AcaciaXXI.floor[FloorType.TopFloor].features}
              floorImage={AcaciaXXI.floor[FloorType.TopFloor].floorImage}
            />
          </div>
        </div>
        <button onClick={() => handlePrototype(AcaciaXXI.name)}>
          ESTOY INTERESADO
        </button>
      </div>
      <div className="prototype-container brown">
        <img
          className="iso"
          src="/assets/casas-velasur/svg-iso-velasur.svg"
          alt=""
        />
        <h2>{AcaciaCVII.name}</h2>
        <div className="text-ground">
          <p>
            Terreno: {AcaciaCVII.terreno} m<sup>2</sup>
          </p>
          <p>
            Construcción: {AcaciaCVII.construccion} m<sup>2</sup>
          </p>
        </div>
        <div className="prototype-container-detail">
          <div className="container-carousel">
            <PrototypeCarousel
              images={AcaciaCVII.gallery}
              setModalConfigProps={setModalConfigProps}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaCVII.floor[FloorType.LowFloor].title}
              surface={AcaciaCVII.floor[FloorType.LowFloor].surface}
              features={AcaciaCVII.floor[FloorType.LowFloor].features}
              floorImage={AcaciaCVII.floor[FloorType.LowFloor].floorImage}
            />
          </div>
          <div className="container-detail">
            <PrototypeDetail
              floorType={AcaciaCVII.floor[FloorType.TopFloor].title}
              surface={AcaciaCVII.floor[FloorType.TopFloor].surface}
              features={AcaciaCVII.floor[FloorType.TopFloor].features}
              floorImage={AcaciaCVII.floor[FloorType.TopFloor].floorImage}
            />
          </div>
        </div>
        <button onClick={() => handlePrototype(AcaciaCVII.name)}>
          ESTOY INTERESADO
        </button>
      </div>
    </>
  );
};

export default PrototypeContainer;
