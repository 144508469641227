import React, {useEffect} from 'react';
import './ModalDispo.scss';
const ModalDispo = (props) => {
    useEffect(()=>{
        var map = document.getElementById('map');
        const dobleClick = () => {
            map.style.width = `150%`;
            map.style.position ="absolute";
            map.style.height="auto";
                map.style.cursor ="pointer"
                map.onmousemove = function(e){
                    map.style.top = `-${e.y}px`
                    let x = e.x / 2;
                    map.style.left =`-${x}px`
                }
            map.ondragstart = function() {
                return false;
            };
        }
        document.getElementById('map').addEventListener('dblclick', dobleClick);
    },[])
    return ( 
        <div className='contenedor_imagen_dispo'>
            <p onClick={()=>{props.setModal(false)}} className='closed'>X</p>
            <div className='imagen'>
                <img id='map' src="/assets/imagen2.jpg" alt="" />
            </div>
        </div>
     );
}
 
export default ModalDispo;