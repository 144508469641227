import React,{useEffect, useRef} from 'react';
import './Disponibilidad.scss'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import 'imagemapster';
import $ from "jquery";
import { useNavigate   } from "react-router-dom";

const Disponibilidad = () => {
    const mapRef = useRef(null);
    const navigate = useNavigate();

    window.addEventListener('resize', (event) => {
        if(event.target.innerWidth > 550){
          $(mapRef.current).mapster('resize', event.target.innerWidth, 0, 100);
        }else{
          $(mapRef.current).mapster('resize', 1000, 0, 100);
        }
    })

    useEffect(() => {
        const initializeImageMapster = () => {
            const options = {
              fillOpacity: 0.5,
              fillColor: "EBE7E1",
              mapKey: 'name',
              isSelectable: false,
              onClick: function(e){
                console.log('key',e)
                if(e.key === "ACACIA"){
                    console.log("acacia")
                    navigate("/privada-acacia");
                }else{
                    if(e.key === "ARTEMISA"){
                        console.log("artemisa")
                        navigate("/privada-artemisa");
                    }
                }
              }
            };
    
            $(mapRef.current).mapster(options);
    
        };
    
        const imgElement = mapRef.current;
        if (imgElement.complete) {
          // La imagen ya está cargada, inicializar ImageMapster directamente
          initializeImageMapster();
        } else {
          // La imagen aún no se ha cargado, esperar al evento 'load' para inicializar ImageMapster
            imgElement.addEventListener('load', initializeImageMapster);
        }
    
        setTimeout(() => {
            window.dispatchEvent(new Event('resize')); 
        }, 300)
    
    
        
      return () => {
        $(mapRef.current).mapster('unbind');
      };
      
    },[]);

    return ( 
        <div className='contenedor_disponibilidad'>
            <div className='lugares-cercanos'>
                <div className='dispo_top'>
                    <AnimationOnScroll
                        duration={2}
                        animateOnce={true}
                        animateIn='animate__fadeIn'>
                        <div className='subtitulo_dispo'>
                            <h2>VIVE <br/> EN GRANDE</h2>
                        </div>
                    </AnimationOnScroll>
                    
                    <div className='texto_dispo'>
                        <p>Las 40 hectáreas de nuestro desarrollo colindan al norte con un siguiente desarrollo para crear una comunidad integral de 150 hectáreas que contará con:</p>
                    </div>
                </div>
                <div className='lugares_dispo'>
                        <AnimationOnScroll
                            className="height-full"
                            duration={2}
                            animateOnce={true}
                            animateIn='animate__fadeIn'>
                        <div className='row_lugar'>
                            <img src="/assets/Iconos/dispo/svg-icon-iglesia.svg" alt="iglesia" />
                            <p>Iglesia</p>
                        </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                        className="height-full"
                            duration={2}
                            animateOnce={true}
                            animateIn='animate__fadeIn'>
                        <div className='row_lugar'>
                            <img src="/assets/Iconos/dispo/svg-icon-plaza-comercial.svg" alt="plaza comercial" />
                            <p>Plaza comercial</p>
                        </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                        className="height-full"
                            duration={2}
                            animateOnce={true}
                            animateIn='animate__fadeIn'>
                        <div className='row_lugar'>
                            <img src="/assets/Iconos/dispo/svg-icon-plaza-armas.svg" alt="plaza armas" />
                            <p>Plaza de armas</p>
                        </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                        className="height-full"
                            duration={2}
                            animateOnce={true}
                            animateIn='animate__fadeIn'>
                        <div className='row_lugar'>
                            <img src="/assets/Iconos/dispo/svg-icon-parque.svg" alt="parque" />
                            <p>3 hectáreas de parque aproximadamente</p>
                        </div>
                        </AnimationOnScroll>
                </div>
            </div>
            <div id="masterplan" className='bottom_mapa fondoMasterplan'>
                <div className='masterplan-text'>
                    <h2>DISPONIBILIDAD</h2>
                    <p>Elige una privada:</p>
                </div>
                <span className='disclamer'>Esta imagen es representativa del proyecto, sus características pueden cambiar sin previo aviso.</span>
                <img src="/assets/Simulador/Mapeado/mappeo-privadas_WEB_07-07-23.jpg" width="2396" height="1346" border="0" alt="" useMap="#mappeo_privadas_WEB_07_07_23_Map" ref={mapRef}/>
                <map name="mappeo_privadas_WEB_07_07_23_Map">
                    <area shape="poly" alt="" coords="1765,546, 1759,548, 1742,552, 1719,559, 1692,568, 1665,579, 1641,591, 1623,604, 1618,608, 1601,618, 1575,632, 1539,650, 1495,669, 1444,686, 1385,702, 1310,587, 1181,534, 1227,514, 1233,512, 1248,505, 1271,496, 1299,485, 1330,475, 1362,464, 1392,457, 1417,452, 1462,443,
                    1468,442, 1485,440,
                    1510,436, 1539,432, 1571,428, 1600,424, 1626,421, 1644,419, 1638,420, 1628,423, 1618,428, 1611,437, 1611,438, 1613,443, 1619,449, 1630,456, 1649,463, 1756,507, 1759,508, 1765,512, 1773,517, 1779,524, 1782,531, 1778,539, 1765,546" href="#olivo"/>
                    <area name="ARTEMISA" shape="poly" alt="" coords="1385,702, 1383,702, 1377,704, 1360,708, 1333,714, 1295,722, 1249,731, 1195,740, 1133,749, 1053,767, 926,801, 799,834, 793,836, 777,841, 752,847, 720,854, 683,860, 645,864, 605,864, 567,860, 533,850, 467,773, 402,697, 525,674, 649,651, 772,628, 868,609, 964,589, 1060,570,
                    1139,548, 1145,547,
                    1159,542, 1181,534, 1310,587, 1385,702" href="#artemisa"/>
                    <area shape="poly" alt="" coords="2386,673, 2293,712, 2168,660, 2042,607, 1917,554, 1906,542, 1890,533, 1872,528, 1858,525, 1852,524, 1761,488, 1670,451, 1661,445, 1661,439, 1665,435, 1670,432, 1673,431, 1715,426, 1740,424, 1766,420, 1788,416, 1803,413, 1809,412, 1829,406, 1844,406, 1849,406, 1938,431,
                    2023,476, 2035,484,
                    2123,534, 2211,584, 2300,635, 2386,673" href="#senna"/>
                    <area shape="poly" alt="" coords="2266,724, 2231,738, 2118,793, 2004,847, 1931,883, 1857,918, 1844,925, 1737,875, 1629,825, 1521,775, 1413,725, 1467,709, 1516,693, 1557,677, 1591,663, 1616,651, 1631,643, 1637,640, 1698,599, 1738,580, 1775,567, 1806,560, 1832,557, 1852,556, 1864,556, 1869,557, 2001,612,
                    2134,668, 2266,724"
                    href="#copal"/>
                    <area name="ACACIA" shape="poly" alt="" coords="1844,925, 1840,927, 1752,970, 1664,1012, 1576,1055, 1450,1114, 1325,1173, 1199,1233, 1090,1204, 980,1176, 879,1140, 808,1081, 719,1038, 690,1020, 672,1004, 663,989, 659,980, 658,976, 667,947, 685,924, 709,906, 733,893, 756,885, 772,880, 778,879, 910,845, 1007,814,
                    1079,795, 1151,775, 1163,772,
                1182,768, 1207,764, 1233,759, 1259,755, 1281,751, 1297,749, 1302,748, 1413,725, 1521,775, 1629,825, 1737,875, 1844,925" href="#acacia"/>
                </map>
            </div>
        </div>
     );
}
 
export default Disponibilidad;