import React from "react"; 
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import './MapBox.scss'

const DEFAULT_MAP_POSITION = [20.509106231909826, -100.40774736222625];
const MapBox = () => {
    let DefaultIcon = L.icon({
        iconSize: [60,60],
        iconUrl: "./assets/Iconos/img-isotipo.png",
    });
    L.Marker.prototype.options.icon = DefaultIcon;
    return (  
        <>
        <MapContainer center={DEFAULT_MAP_POSITION} zoom={15} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://api.mapbox.com/styles/v1/stephaniefg/cllh88eb901zh01qnal8q6md2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3RlcGhhbmllZmciLCJhIjoiY2xqZzNsZWY5MDV6YzNxbGp2dnZtaTZrMiJ9.c2K26WPWBL5RBTuT-Lqoig&zoomwheel=true&fresh=true#14.57/20.51052/-100.40175"
    />
    <Marker position={DEFAULT_MAP_POSITION}>
      <Popup>
        Velasur
      </Popup>
    </Marker>  
  </MapContainer>
        </>
    );
}
export default MapBox;