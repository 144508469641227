import React, { useState, useEffect } from 'react';
import Principal from "../Principal/Principal";
import Recorrido from "../Recorrido/Recorrido";
import Disponibilidad from "../Disponibilidad/Disponibilidad";
import Ubicacion from "../ubicacion/Ubicacion";
import Concepto from "../Concepto/Concepto";
import ConceptoVideo from "../ConceptoVideo/ConceptoVideo";
import Slider from "../Slider/Slider";
import CasasVelasurBanner from '../Banner/CasasVelasurBanner';
import { SwiperSlide } from "swiper/react";
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useNavigate, useLocation } from 'react-router-dom';
import RecorridoHome from '../RecorridoHome/RecorridoHome';
import './Home.scss'
const Home1 = () => {
    const navigate = useNavigate();
    const actualUrl = useLocation();

    const scrollTarget = (target) => { 
        console.log('scroll',target)
        scroller.scrollTo(target, {smooth: true, duration: 700})
      };
      
      const scrollToPage =  (target) => {
        console.log('app',target)
        navigate(`/${target}`)
        scrollTarget(target.slice(1));
      };
      useEffect(()=>{
        scrollToPage(actualUrl.hash)
      },[]);
    return(
        <>
        <div>
            <Principal/>
            <CasasVelasurBanner/>
            <ConceptoVideo/>
            <RecorridoHome></RecorridoHome>
            {/* <div id="macroproyecto" className="slider-galeria">
                <Slider nav={false} pag={true}>
                    <SwiperSlide>
                        <img src="/assets/galeria/img-aereo1-velasur.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/galeria/img-aereo2-velasur.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/galeria/img-aereo3-velasur.jpg" alt="" />
                    </SwiperSlide>
                </Slider>
            </div> */}
            <Concepto
                titulo1={"COMUNIDAD"}
                titulo2={"RESIDENCIAL"}
            >
                <p>En<strong> VELASUR </strong>hemos cuidado cada detalle para diseñar un plan maestro que se ajuste a su estilo de vida activo y en contacto con la naturaleza. 
                Nuestra comunidad residencial ofrece algo más que un lugar para vivir, nos hemos asegurado de incluir ambientes que coexisten con el entorno natural.</p>
            </Concepto>
            <Disponibilidad/>
            <Ubicacion/>
        </div>
        </>
    );
}
export default Home1