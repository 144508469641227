import React, { useState,useEffect } from 'react';
import './Recorrido.scss'
import View360 from '../View360/View360';
const Recorrido = () => {
    const [idScene, setId] = useState("acceso");
    const [yaw, setyaw] = useState(50)
    const [pitch, setpitch] = useState(0)

    const handleImagen = (tipo) =>{
        const removeElement = document.getElementsByClassName("active");
        removeElement[0].classList.remove("active")
        const element = document.getElementById(tipo);
        element.classList.add("active");
        if(tipo==="acceso"){
            console.log("acceso")
            setId("acceso")
            setyaw(50)
            setpitch(0)
        }else{
            if(tipo==="lago"){
                setyaw(-30)
                setpitch(0)
                setId("lago")
            }else{
                if(tipo==="alberca"){
                    setId("alberca")
                    console.log("alberca")
                    setyaw(-90)
                    setpitch(0)
                }
            }
        }
    }

    return ( 
        <div className='contenedor_recorrido'>
            {
                idScene ==="acceso" &&
                <View360 yaw={yaw} pitch={pitch} urlImagen="/assets/360/1.acceso.jpg" idScene="acceso"></View360>
            }
                        {
                idScene ==="lago" &&
                <View360 yaw={yaw} pitch={pitch} urlImagen="/assets/360/2.lago.jpg" idScene="lago"></View360>
            }
                        {
                idScene ==="alberca" &&
                <View360 yaw={yaw} pitch={pitch} urlImagen="/assets/360/3.alberca.jpg" idScene="alberca"></View360>
            }
            <span className='disclamer'>Esta imagen es representativa del proyecto, sus características pueden cambiar sin previo aviso.</span>
            <div className='options'>
                <button id="acceso" className="active" onClick={()=>{handleImagen("acceso")}}>ACCESO</button>
                <button id="lago" onClick={()=>{handleImagen("lago")}}>LAGO</button>
                <button id="alberca" onClick={()=>{handleImagen("alberca")}}>ALBERCA</button>
            </div>
        </div>
     );
}
 
export default Recorrido;