import React, { useState } from "react";
import Header from "./Componentes/Header/Header";
import Footer from "./Componentes/Footer/Footer";
import Modal from "./Componentes/Modal/Modal";
import Home1 from "./Componentes/Home1/Home1.js";
import Aviso from "./Componentes/Aviso/Aviso";
import ModalDispo from "./Componentes/ModalDispo/ModalDispo";
import Video from "./Componentes/Video/Video";
import PrivadaAcacia from "./Componentes/PrivadaAcacia/PrivadaAcacia";
import PrivadaArtemisa from "./Componentes/PrivadaArtemisa/PrivadaArtemisa";
import Mapa from "./Componentes/Mapa/Mapa";
import "./App.scss";
import CasasVelasur from "./Componentes/Casas-Velasur/CasasVelasur";
import { CSSTransition } from "react-transition-group";
import Stiky from "./Componentes/Stiky/Stiky";
import Galeria from "./Componentes/Galeria/Galeria";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import ModalGaleria from "./Componentes/ModalGaleria/ModalGaleria";
import ModalSwiper from "./Componentes/PrototypeCarousel/ModalSwiper.js";
function App() {
  const [showModal, setModal] = useState(false);
  const [showModalAviso, setModalAviso] = useState(false);
  const [showVideo, setVideo] = useState(false);
  const [showImagen, setImagen] = useState(false);
  const [modalConfigProps, setModalConfigProps] = useState({
    images: [],
    activeSlideIndex: 0,
    isOpen: false,
  });
  const [urlVideo, setUrlVideo] = useState("");
  const [open, setOpen] = useState("");
  const [OpenGaleria, setOpenGaleria] = useState(false);
  const [typeGaleria, setTypeGaleria] = useState("");
  const [urlGaleria, setUrlGaleria] = useState("");

  return (
    <div className={`App ${open}`}>
      <CSSTransition
        in={OpenGaleria}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <ModalGaleria
          setOpenGaleria={setOpenGaleria}
          typeGaleria={typeGaleria}
          urlGaleria={urlGaleria}
        />
      </CSSTransition>

      <CSSTransition
        in={showImagen}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <ModalDispo setModal={setImagen} />
      </CSSTransition>
      <CSSTransition
        in={showModal}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Modal setModalAviso={setModalAviso} setModal={setModal} />
      </CSSTransition>
      <CSSTransition
        in={showModalAviso}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Aviso setModalAviso={setModalAviso} />
      </CSSTransition>
      <CSSTransition
        in={showVideo}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Video urlVideo={urlVideo} setVideo={setVideo} />
      </CSSTransition>
      <Stiky setImagen={setImagen} setModal={setModal} />
      <Header open={open} setOpen={setOpen} setImagen={setImagen} />
      {modalConfigProps.isOpen && (
        <ModalSwiper
          onModalClose={() => {
            setModalConfigProps((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
          images={modalConfigProps.images}
          currentSlideIndex={modalConfigProps.activeSlideIndex}
        />
      )}
      <Routes>
        <Route exact path="/" element={<Home1 />} />
        <Route exact path="/privada-artemisa" element={<PrivadaArtemisa />} />
        <Route exact path="/privada-acacia" element={<PrivadaAcacia />} />
        <Route
          path="/Galeria"
          element={
            <Galeria
              setUrlGaleria={setUrlGaleria}
              setOpenGaleria={setOpenGaleria}
              setTypeGaleria={setTypeGaleria}
            ></Galeria>
          }
        />
        <Route
          path="/casas-velasur"
          element={<CasasVelasur setModalConfigProps={setModalConfigProps} />}
        />
      </Routes>
      <Mapa />
      <Footer setModalAviso={setModalAviso} />
    </div>
  );
}

export default App;
