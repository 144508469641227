import React from 'react';
import './Amenidades.scss';
import Slider from '../Slider/Slider';
import { SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
const Amenidades = () => {
    return ( 
        <div id="amenidades" className='contenedor_amenidades'>
            <div className='amenidades_top'>
            <AnimationOnScroll
                        duration={2}
                        animateOnce={true}
                        animateIn='animate__fadeIn'>
                <div className='subtitulo_ame'>
                    <h2>
                        AMENIDADES <br/> EXCLUSIVAS
                    </h2>
                </div>
            </AnimationOnScroll>
                <div className='list_ame'>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-caseta.svg" alt="Caseta de acceso" />
                        <p>Acceso principal con caseta de control las 24 horas</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-juegos-infantiles.svg" alt="Juegos infantiles" />
                        <p>Juegos infantiles</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-lineal.svg" alt="Parque lineal" />
                        <p>8 mil m2 de parque lineal</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-dogpark.svg" alt="Dog park" />
                        <p>Dog Park</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-ciclovia.svg" alt="Ciclovia" />
                        <p>Ciclovía y pista para correr periférica de más de 2,500 metros</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-coworking.svg" alt="Coworking" />
                        <p>Coworking al aire libre</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-area-verde.svg" alt="Area verde" />
                        <p>2,500 m2 de área verde por privada</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-padel.svg" alt="Canchas" />
                        <p>Cancha de pádel</p>
                    </div>
                    {/* <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-lounge.svg" alt="Area lounge" />
                        <p>Área lounge con fogata</p>
                    </div> */}
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-acceso.svg" alt="Doble acceso" />
                        <p>Doble punto de acceso</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Iconos/Amenidades/svg-icon-picnics.svg" alt="Area de picnics" />
                        <p>Área de picnic</p>
                    </div>
                </div>
            </div>
            <div id="galeria" className='amenidades_bottom'>
            <Slider nav={false} pag={true}>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/amenidades/img-velasur-casa-club.jpg" alt="Velasur" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/amenidades/img-velasur-ciclovia.jpg" alt="Velasur" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/amenidades/img-velasur-lago.jpg" alt="Velasur" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/amenidades/img-velasur-padel.jpg" alt="Velasur" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/amenidades/img-velasur-picnics.jpg" alt="Velasur" />
                        </div>
                    </SwiperSlide>
            </Slider>
            </div>
        </div>
     );
}
 
export default Amenidades;