import React, { useEffect } from "react";
import "./Galeria.scss";
const Galeria = (props) => {
  const handleShowModalGaleria = (tipo, url) => {
    props.setTypeGaleria(tipo);
    props.setUrlGaleria(url);
    props.setOpenGaleria(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contenedor_galeria">
      <div className="grid">
        {/* <div className='img-grid' onClick={()=>handleShowModalGaleria('video','/assets/galeria/gallery/videos/1.mp4')}>
                    <video muted loop autoPlay src="/assets/galeria/gallery/videos/1.mp4"></video>
                    <div className='caption'>
                    </div>
                </div>
                <div className='img-grid' onClick={()=>handleShowModalGaleria('video','/assets/galeria/gallery/videos/2.mp4')}>
                    <video muted loop autoPlay src="/assets/galeria/gallery/videos/2.mp4"></video>
                    <div className='caption'>
                    </div>
                </div> */}
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/1.png")
          }
        >
          <img src="/assets/galeria/gallery/1.png" alt="" />
          <div className="caption">
            <p>Entrada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/2.png")
          }
        >
          <img src="/assets/galeria/gallery/2.png" alt="" />
          <div className="caption">
            <p>Entrada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/3.png")
          }
        >
          <img src="/assets/galeria/gallery/3.png" alt="" />
          <div className="caption">
            <p>Entrada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/4.png")
          }
        >
          <img src="/assets/galeria/gallery/4.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/5.png")
          }
        >
          <img src="/assets/galeria/gallery/5.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/6.png")
          }
        >
          <img src="/assets/galeria/gallery/6.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/7.png")
          }
        >
          <img src="/assets/galeria/gallery/7.png" alt="" />
          <div className="caption">
            <p>Entrada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/8.png")
          }
        >
          <img src="/assets/galeria/gallery/8.png" alt="" />
          <div className="caption">
            <p>Entrada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/10.png")
          }
        >
          <img src="/assets/galeria/gallery/10.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/12.png")
          }
        >
          <img src="/assets/galeria/gallery/12.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/13.png")
          }
        >
          <img src="/assets/galeria/gallery/13.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/14.png")
          }
        >
          <img src="/assets/galeria/gallery/14.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/15.png")
          }
        >
          <img src="/assets/galeria/gallery/15.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/16.png")
          }
        >
          <img src="/assets/galeria/gallery/16.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/17.png")
          }
        >
          <img src="/assets/galeria/gallery/17.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/18.png")
          }
        >
          <img src="/assets/galeria/gallery/18.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/19.png")
          }
        >
          <img src="/assets/galeria/gallery/19.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/20.png")
          }
        >
          <img src="/assets/galeria/gallery/20.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/21.png")
          }
        >
          <img src="/assets/galeria/gallery/21.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/22.png")
          }
        >
          <img src="/assets/galeria/gallery/22.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/23.png")
          }
        >
          <img src="/assets/galeria/gallery/23.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/24.png")
          }
        >
          <img src="/assets/galeria/gallery/24.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/25.png")
          }
        >
          <img src="/assets/galeria/gallery/25.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/26.png")
          }
        >
          <img src="/assets/galeria/gallery/26.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/27.png")
          }
        >
          <img src="/assets/galeria/gallery/27.png" alt="" />
          <div className="caption">
            <p>Canchas</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/28.png")
          }
        >
          <img src="/assets/galeria/gallery/28.png" alt="" />
          <div className="caption">
            <p>Alberca</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/29.png")
          }
        >
          <img src="/assets/galeria/gallery/29.png" alt="" />
          <div className="caption">
            <p>Sala</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/30.png")
          }
        >
          <img src="/assets/galeria/gallery/30.png" alt="" />
          <div className="caption">
            <p>Vista Aerea</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/31.png")
          }
        >
          <img src="/assets/galeria/gallery/31.png" alt="" />
          <div className="caption">
            <p>Vista Aerea</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/32.png")
          }
        >
          <img src="/assets/galeria/gallery/32.png" alt="" />
          <div className="caption">
            <p>Vista Aerea</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/33.png")
          }
        >
          <img src="/assets/galeria/gallery/33.png" alt="" />
          <div className="caption">
            <p>Area de Juegos</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/34.png")
          }
        >
          <img src="/assets/galeria/gallery/34.png" alt="" />
          <div className="caption">
            <p>Alberca</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/35.png")
          }
        >
          <img src="/assets/galeria/gallery/35.png" alt="" />
          <div className="caption">
            <p>Alberca</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/36.png")
          }
        >
          <img src="/assets/galeria/gallery/36.png" alt="" />
          <div className="caption">
            <p>Alberca</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/37.png")
          }
        >
          <img src="/assets/galeria/gallery/37.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/38.png")
          }
        >
          <img src="/assets/galeria/gallery/38.png" alt="" />
          <div className="caption">
            <p>Cancha</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/39.png")
          }
        >
          <img src="/assets/galeria/gallery/39.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/40.png")
          }
        >
          <img src="/assets/galeria/gallery/40.png" alt="" />
          <div className="caption">
            <p>Vista Aerea</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/41.png")
          }
        >
          <img src="/assets/galeria/gallery/41.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/46.png")
          }
        >
          <img src="/assets/galeria/gallery/46.png" alt="" />
          <div className="caption">
            <p>Alberca</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/47.png")
          }
        >
          <img src="/assets/galeria/gallery/47.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/48.png")
          }
        >
          <img src="/assets/galeria/gallery/48.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/49.png")
          }
        >
          <img src="/assets/galeria/gallery/49.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/galeria/gallery/50.png")
          }
        >
          <img src="/assets/galeria/gallery/50.png" alt="" />
          <div className="caption">
            <p>Parque</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_1.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_1.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_2.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_2.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_4.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_4.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_5.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_5.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_6.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_6.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_7.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_7.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_10.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_10.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_11.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_11.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img_velasur_12.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img_velasur_12.jpg" alt="" />
          <div className="caption">
            <p>Casa Club</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galeria;
