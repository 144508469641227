import React, { useMemo, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/core";
import "./PrototypeCarousel.scss";

const PrototypeCarousel = ({ images = [], setModalConfigProps }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const gallerySlidesContent = useMemo(() =>
    images.map(
      (img, index) => (
        <SwiperSlide key={index}>
          <img src={img} />
        </SwiperSlide>
      ),
      [images]
    )
  );
  const handleModalGallery = () => {
    setModalConfigProps({ images, activeSlideIndex, isOpen: true });
  };

  return (
    <>
      <div className="container_slider_thumbnail">
        <Swiper
          onSlideChange={(swiper) => {
            setActiveSlideIndex(swiper.activeIndex - 1);
          }}
          onClick={handleModalGallery}
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={true}
          loop={true}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Pagination]}
          className="prototype-carousel-slides"
        >
          {gallerySlidesContent}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={15}
          slidesPerView={4}
          loop={true}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="prototype-carousel-thumb"
        >
          {gallerySlidesContent}
        </Swiper>
      </div>
    </>
  );
};

export default PrototypeCarousel;
