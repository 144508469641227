import React from 'react';
// Import Swiper React components
import { Swiper } from "swiper/react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
  
import SwiperCore, { 
    Autoplay,
    Navigation,
    Pagination
  } from 'swiper/core';
  
  // install Swiper modules
SwiperCore.use([Pagination,Autoplay, Navigation ]);
const Slider = (props) => {
    const {children} = props;
    return ( 
        <>
            <Swiper 
                spaceBetween={30} 
                centeredSlides={true} 
                loop={true}  
                pagination={props.pag}
                navigation={props.nav}
                className="mySwiper">
                {children}
            </Swiper>
        </>
     );
}
 
export default Slider;