import React from 'react'
import './CasasVelasurBanner.scss'
import { Link } from 'react-router-dom';
const CasasVelasurBanner = () => {
return(
<div>
    <div className='banner-principal'><Link to='/casas-velasur'>
        <div className='img-logo'>
        <img src="/assets/Logos/svg-iso-back-casas-velasur.svg"/>
        </div>
        <div className='contenedor-banner-letras'>
        <h1>CASAS VELASUR</h1>
        <p>Ubicadas en VELASUR dentro de PRIVADA ACACIA</p>
        </div>
        <div className='banner-letras-separadas'>
        <p>VIVE LA EXCLUSIVIDAD</p>
        </div>
        </Link>
    </div>
</div>
);
}
export default CasasVelasurBanner;