import React,{useState} from 'react';
import './Mapa.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import MapBox from '../MapBox/MapBox';

const Mapa = (props) => {
    const destinations = {
        ORIGEN:{
            lat: 20.509106231909826,
            lng: -100.40774736222625
        }
    }
    let [selectdestination, setDestination] = useState(null);
    return (
        <div id="ubicacion" className='contenedor_mapa'>
            <div className='mapa'>
                <MapBox/>
            </div>
            <div className='ubicaciones'>
                <div className='top'>
                <AnimationOnScroll
                        duration={2}
                        animateOnce={true}
                        animateIn='animate__fadeIn'>
                    <div className='top_subtitulo'>
                        <h2>CERCA DE <br/> TODO</h2>
                    </div>
                </AnimationOnScroll>
                    <div className='top_texto'>
                        <p>Blvd. Metropolitano Corregidora-Huimilpan S/N, km 3.5, Corregidora, Qro.</p>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='bottom_ubicaciones'>
                        <div className='row_list'>
                            <img src="/assets/favicon/apple-icon-57x57.png" alt="" />
                            <p>a 1 minuto de importantes centros de estudio</p>
                        </div>
                        <div className='row_list'>
                        <img src="/assets/favicon/apple-icon-57x57.png" alt="" />
                            <p>a 5 minutos del Libramiento Surponiente</p>
                        </div>
                        <div className='row_list'>
                        <img src="/assets/favicon/apple-icon-57x57.png" alt="" />
                            <p>a 10 minutos de Av. Constituyentes</p>
                        </div>
                        <div className='row_list'>
                        <img src="/assets/favicon/apple-icon-57x57.png" alt="" />
                            <p>a 5 minutos de centros comerciales y salas de cine</p>
                        </div>
                        <div className='row_list'>
                        <img src="/assets/favicon/apple-icon-57x57.png" alt="" />
                            <p>a 12 minutos de la ciudad Santiago de Querétaro</p>
                        </div>
                    </div>
                    <div className='mapas'>
                        <a target={'_blank'} href="https://goo.gl/maps/9yMnUXV9ENvEhrqg7"><img src="/assets/Logos/svg-icon-maps.svg" alt="" /></a>
                        <a target={'_blank'} href="https://www.waze.com/en/live-map/directions/mx/qro./corregidora/velasur?place=ChIJMS96ivdF04URmNPxYOsTYGY&utm_campaign=waze_website&utm_medium=website_menu&utm_source=waze_website"><img src="/assets/Logos/svg-icon-waze.svg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Mapa;