import React from 'react';
import './AmenidadesDesc.scss';
import Slider from '../Slider/SliderStatic';
import { SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
const AmenidadesDesc = (props) => {
    return ( 
        <div id="amenmapa" className='contenedor_amenidades_desc'>
            <img className='contenedor_amenidades_desc_fondo' src="/assets/img-velasur-privada-acacia.jpg" alt="" />
                        <div className="contenedor_amenidades_desc__slider">
                            <div className='contenedor_amenidades_desc__slider_card'>
                            <AnimationOnScroll
                                duration={2}
                                animateOnce={true}
                                animateIn='animate__fadeIn'>
                                <div className='contenedor_amenidades_desc__slider_card_logo'>
                                    <img src="/assets/Logos/svg-logo-acacia.svg" alt="Logo Acacia" />
                                </div>
                                </AnimationOnScroll>
                                <div className='contenedor_amenidades_desc__slider_card_metros'>
                                        <p>147 Lotes tipo 11 x 24 mts (264 m2)</p>
                                </div>
                                <div className='contenedor_amenidades_desc__slider_card_lista'>
                                        <div className='amenidad'>
                                           <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Casa club</p>
                                           </div>
                                        </div>
                                        <div className='amenidad'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Áreas recreativas</p>
                                            </div>
                                        </div>
                                        <div className='amenidad'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Alberca</p>
                                            </div>
                                        </div>
                                        <div className='amenidad'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>2,500 m2 de área verde</p>
                                            </div>
                                        </div>
                                        <div className='amenidad'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Lago artificial</p>
                                            </div>
                                        </div>
                                        <div className='amenidad'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Acceso controlado</p>
                                            </div>
                                        </div>
                                </div>
                                <div className='button_dispo'>
                                        <a href='https://disponibilidad.velasur.mx/privada-acacia'>VER DISPONIBILIDAD</a>
                                </div>
                            </div>
                        </div>
        </div>
     );
}
 
export default AmenidadesDesc;