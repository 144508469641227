import React, { useState, useEffect } from "react";
import "./PrivadaArtemisa.scss";
import "../RecorridoArtemisa/RecorridoArtemisa";
import RecorridoArtemisa from "../RecorridoArtemisa/RecorridoArtemisa";
import AmenidadesArtemisa from "../AmenidadesArtemisa/AmenidadesArtemisa";
import AmenidadesDescArtemisa from "../AmenidadesDescArtemisa/AmenidadesDesc";
const PrivadaArtemisa = () => {
  const [showStiky, setShowStiky] = useState("ocultar");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="amenmapa" className="contenedor_home_artemisa_desc">
        <img
          className="contenedor_home_artemisa_fondo"
          src="/assets/Artemisa/img-cover-privada-artemisa.jpg"
          alt=""
        />
        <div className="contenedor_home_artemisa__slider_card_logo">
          <div className="contenedor-home-principal-artemisa">
            <img
              src="/assets/Artemisa/img-logo-artemisa.svg"
              alt="Logo Artemisa"
            />
            <div className="button_dispo_artemisa">
              <a href="https://disponibilidad.velasur.mx/privada-artemisa">
                VER DISPONIBILIDAD
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-info-home-artemisa">
          <div className="icon-scroll"></div>
          <div className="whatsapp">
            <div className={showStiky}>
              <a
                target={"_blank"}
                href="https://api.whatsapp.com/send?phone=524423228840&text="
              >
                <img src="/assets/Logos/svg-icon-whatsapp.svg" alt="Whatsapp" />
              </a>
            </div>
          </div>
          <div className="texto_artemisa">
            <p>LOTES RESIDENCIALES PREMIUM</p>
          </div>
        </div>
      </div>
      <RecorridoArtemisa />
      <AmenidadesArtemisa />
      <AmenidadesDescArtemisa />
    </>
  );
};
export default PrivadaArtemisa;
