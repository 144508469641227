import React from 'react';
import './Ubicacion.scss'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
const Ubicacion = (props) => {
    const handleVideo = () =>{
        props.setVideo(true)
        props.setUrlVideo("nNg9KYR4qQs")
    }
    return ( 
        <div className='contenedor_ubicacion'>
            <div className='contenedor_ubicacion__left'>
                <AnimationOnScroll
                    duration={2}
                    animateOnce={true}
                    animateIn='animate__fadeIn'>
                    <div className='contenedor_ubicacion__left_subtitulo'>
                        <h2>UBICACIÓN <br/> PRIVILEGIADA</h2>
                    </div>
                </AnimationOnScroll>
                <div className='contenedor_ubicacion__left_texto'>
                    <p>VELASUR se encuentra en el histórico municipio de Corregidora, a una conveniente distancia de las principales vialidades que conectan con el centro de Querétaro, Celaya y Ciudad de México, y de centros de estudio y zonas comerciales..</p>
                </div>
                <div className='contenedor_ubicacion__left_button'>
                    <a href="#ubicacion">VER UBICACIÓN</a>
                </div>
            </div>
            <div className='contenedor_ubicacion__right'>
                {/* <img className='play' src="/assets/Logos/boton-de-play.png" alt="" onClick={()=>handleVideo()}/> */}
                <div className='contenedor_ubicacion__right_video'>
                   <video src="/assets/Video/loop-corregidora.mp4" autoPlay muted loop></video>
                </div>
            </div>
        </div>
     );
}
 
export default Ubicacion;