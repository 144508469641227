import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import './Concepto.scss';
const Concepto = (props) => {
    const {children} = props
    return ( 
        <div className='contenedor_concepto'>
            <AnimationOnScroll
                duration={2}
                animateOnce={true}
                animateIn='animate__fadeIn'>
                <div className='titulo_concepto'>
                    <h1>{props.titulo1} <br/> {props.titulo2}</h1>
                </div>
            </AnimationOnScroll>
            <div className='texto_concepto'>
               {children}
            </div>
            <div className='button_dispo'>
                <a href='/#masterplan'>VER DISPONIBILIDAD</a>
            </div>
        </div>
     );
}
 
export default Concepto;