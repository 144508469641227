import React, { useState } from "react";
import * as yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import "./Modal.scss";
const Modal = (props) => {
  const [nombre, colocarNombre] = useState("");
  const [tel, colocarTel] = useState("");
  const [email, colocarEmail] = useState("");
  const [recaptcha, setreCaptcha] = useState("");
  const [errors, setErrors] = useState([]);
  const [reqname, setRequerido] = useState("");
  const [reqtel, setReqtel] = useState("");
  //let [res,setRes] = useState({});
  const validationSchema = yup.object({
    nombre: yup.string().required("El nombre es requerido"),
    tel: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "El numero de teléfono no es válido"
      )
      .min(10, "Mínimo 10 dígitos")
      .max(10, "Máximo 10 dígitos")
      .required("El teléfono es requerido"),
    email: yup.string().email(),
    recaptcha: yup.string().required("Resuelva el recaptcha"),
  });

  const submit = async (e) => {
    const values = {
      nombre: nombre,
      tel: tel,
      email: email,
      recaptcha: recaptcha,
    };

    const isFormValid = await validationSchema.isValid(values);
    // console.log('is form valid? ', isFormValid)

    if (isFormValid) {
      // console.log('todo chido, enviamos el correo')
      const obj = {
        nombre: nombre,
        telefono: tel,
        email: email,
      };

      const res = await axios.post("https://velasur.mx/php/index.php", obj);
      console.log(res.data);
      if (res.data.success) {
        swal("Enviado", "Los datos han sido enviados correctamente", "success");
        colocarNombre("");
        colocarEmail("");
        colocarTel("");

        var link = document.createElement("a");
        link.href = "/assets/brochure/Brochure.pdf";
        link.target = "_blank";
        link.download = "Brochure.pdf";
        link.dispatchEvent(new MouseEvent("click"));
      } else {
        if (res.data.error) {
          swal("Error", "Ocurrió un problema al enviar el correo", "error");
        }
      }
    } else {
      validationSchema
        .validate(values, { abortEarly: false })
        .catch((error) => {
          // console.log('single error main: ', error)
          // console.log('error inner: ', error.inner)
          const errors = error.inner.map((err) => {
            //   console.log('err path: ', err.path)
            return err.errors[0];
          });
          setErrors(errors);
          //   console.log('errors: ', errors)
        });
    }
  };
  const onChangeName = (evt) => {
    colocarNombre(evt.currentTarget.value);
    setRequerido("");
  };
  const onChangeTel = (evt) => {
    colocarTel(evt.currentTarget.value);
    setReqtel("");
  };
  const onChangeEmail = (evt) => {
    colocarEmail(evt.currentTarget.value);
  };

  const onChange = (val) => {
    setreCaptcha(val);
  };
  return (
    <div className="contenedor_modal">
      <p
        onClick={() => {
          props.setModal(false);
        }}
        className="closed"
      >
        X
      </p>
      <div className="contenedor_modal_form">
        <img src="/assets/Logos/svg-logo-velasur.svg" alt="" />
        <p>Déjanos tus datos y descarga nuestro brochure</p>
        <div className="errores">
          <ul>
            {errors.map((error) => {
              // console.log(error)
              return (
                <li>{error}</li>
                // <p>{error}</p>
              );
            })}
          </ul>
        </div>
        <form>
          <input
            type="text"
            placeholder="Nombre"
            value={nombre}
            onChange={onChangeName}
            required
          />
          <input
            type="text"
            placeholder="Teléfono"
            value={tel}
            onChange={onChangeTel}
            required
            pattern="[0-9]{10}"
          />
          <input
            type="email"
            placeholder="Correo"
            value={email}
            onChange={onChangeEmail}
          />
          <p>
            Al enviar acepta nuestra{" "}
            <a
              onClick={() => {
                props.setModalAviso(true);
              }}
            >
              Politica de privacidad
            </a>
          </p>
          <ReCAPTCHA
            sitekey="6Lcti0YfAAAAAJ82nMtSbh-N19afeVlBRUp10qhB"
            onChange={onChange}
          />
          <a
            className="button_send"
            onClick={() => {
              submit();
            }}
          >
            ENVIAR
          </a>
        </form>
      </div>
    </div>
  );
};

export default Modal;
