import React,{useEffect, useState} from 'react';
import ReactPannellum from "react-pannellum";
import './View360.scss'
const View360 = (props) => {

    const [hfov, sethfov] = useState(100)
    useEffect(()=>{
        if (window.screen.width <= 550) {
            sethfov(60)
            console.log("movil")
        }
    },[])
    const config = {
        autoRotate: -2,
        autoLoad: true,
        mouseZoom: false,
        pitch: props.pitch,
        yaw: props.yaw,
        hfov: hfov
    };

    return ( 
        <div className='contenedor_360'>
        <ReactPannellum
            id="view"
            sceneId={props.idScene}
            imageSource={props.urlImagen}
            config={config}
        />
        </div>
     );
}
 
export default View360;