import React from 'react';
import './Aviso.scss'
const Aviso = (props) => {
    return ( 
        <div className='contenedor_aviso'>
             <p onClick={()=>{props.setModalAviso(false)}} className='closed'>X</p>
            <div className='contenedor_aviso_card'>
                <h1>AVISO DE PRIVACIDAD</h1>
                <div className='contenedor_aviso_card_texto'>
                    <p>Estimado cliente:
                    </p>
                    <p>
                        Para realizar el proceso de compra de vivienda, solicitar información sobre nuestros fraccionamientos u oferta de vivienda y para evaluar la calidad del servicio que le brindamos es necesario recabar información personal, 
                        misma que permanecerá en nuestras bases de datos.
                    </p>
                    <p>
                        Para los fines antes mencionados, requerimos los siguientes datos personales: Nombre, fecha de nacimiento, estado civil, 
                        teléfono (s), email, datos de su cónyuge, referencias personales, número de seguro social o ISSSTE (cuando aplique), empleo actual entre otros.
                    </p>
                    <p>
                        Grupo Velas Division Construccion S.A. de C.V. con domicilio en Carretera Libramiento Tampico-Mante Km. 12.8 int. 104 Col. La Españita C.P. 89609, 
                        Altamira Tamaulipas, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
                    </p>
                    <p>
                        Usted tiene derecho para acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, 
                        a través de los procedimientos que hemos implementado antes de la adquisición de la vivienda ya que son necesarios para cumplir con una obligación legalmente adquirida por usted. 
                        Para conocer dichos procedimientos, los requisitos y plazos, se puede poner en contacto con nuestro departamento de servicios al cliente a través del correo electrónico: 
                        serviciosalcliente@grupovelas.com.mx, entendiendo que al cancelar sus datos antes de la adquisición de la vivienda nos imposibilita seguir con este proceso.
                    </p>
                    <p>
                        Asimismo, le informamos que sus datos personales no pueden ser transferidos y no serán tratados fuera de la empresa, 
                        salvo por los que tramites de financiamiento ante INFONAVIT, ISSSTE, Instituciones bancarias y otras relacionadas nos obliguen a proporcionarlos sin fines de lucro o beneficio propio.
                    </p>
                    <p>
                        Eventualmente podría recibir promocionales vía email o telefónicamente. Si desea no recibirlos puede solicitar la suspensión de los mismos a través del correo electrónico: servicioalcliente@grupovelas.com.mx.
                        Cualquier modificación a este aviso de privacidad podrá consultarla a través de su promotor de venta, en casas muestra, puntos de venta o acudir a las instalaciones de servicios al cliente de nuestras sucursales.
                    </p>
                </div>
            </div>
        </div>
     );
}
 
export default Aviso;