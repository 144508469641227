import React from 'react';
import './AmenidadesDesc.scss';
import Slider from '../Slider/SliderStatic';
import { SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

const AmenidadesDescArtemisa = (props) => {
return(
    <>
        <div id="amenmapa" className='contenedor_amenidades_desc_artemisa'>
            <img className='contenedor_amenidades_desc_fondo_artemisa' src="/assets/img-velasur-privada-acacia.jpg" alt="" />
            <div className='contenedor_amenidades_desc__slider_card'>
                                <AnimationOnScroll
                                    duration={2}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                <div className='contenedor_amenidades_desc__slider_card_logo_artemisa'>
                                    <img src="/assets/Artemisa/img-logo-artemisa.svg" alt="Logo Artemisa" />
                                </div>
                                </AnimationOnScroll>
                                <div className='contenedor_amenidades_desc__slider_card_metros_artemisa'>
                                        <p>179 Lotes tipo 11 x 24 mts (264 m2)</p>
                                </div>
                                <div className='contenedor_amenidades_desc__slider_card_lista_artemisa'>
                                        <div className='amenidad_artemisa'>
                                           <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Casa club</p>
                                           </div>
                                        </div>
                                        <div className='amenidad_artemisa'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Áreas recreativas</p>
                                            </div>
                                        </div>
                                        <div className='amenidad_artemisa'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Alberca privada</p>
                                            </div>
                                        </div>
                                        <div className='amenidad_artemisa'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>1,600 m2 de área verde</p>
                                            </div>
                                        </div>
                                        <div className='amenidad_artemisa'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Juegos infantiles</p>
                                            </div>
                                        </div>
                                        <div className='amenidad_artemisa'>
                                            <div>
                                                <img src="/assets/Iconos/svg-icon-iso.svg" alt="icono" />
                                                <p>Acceso controlado</p>
                                            </div>
                                        </div>
                                </div>
                                <div className='button_dispon_artemisa'>
                                        <a href='https://disponibilidad.velasur.mx/privada-artemisa'>VER DISPONIBILIDAD</a>
                                </div>
                                </div></div>
    </>
);
}
export default AmenidadesDescArtemisa;