import React,{ useRef} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import swal from 'sweetalert';

const FormPrototype = ({setModal,prototype}) => {

    const captchaRef = useRef();

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Mínimo 2 caracteres').max(100, 'Máximo 100 caracteres').required('Campo requerido'),
        phone: Yup.string().min(10,'Se esperan al menos 10 dígitos').max(10,'Se esperan al máximo 10 dígitos').required('Campo requerido'),
        email: Yup.string().email('Correo Invalido').required('Campo requerido'),
        recaptcha: Yup.string().required("Llenar captcha")
    });

    return ( 
        <div className='contenedor_modal'>
            <p onClick={()=>{setModal(false)}} className='closed'>X</p>
            <div className='contenedor_modal_form'>
                <img src="/assets/Logos/svg-logo-velasur.svg" alt="" />
                <p className='title-prototype'>{prototype}</p>
                <p>Déjanos tus datos y nos comunicaremos contigo</p>
                <Formik
                    initialValues={{
                        name: '',
                        phone: '',
                        email: '',
                        recaptcha: ''
                    }}
                    validationSchema={SignupSchema}
                    onSubmit = { async (values,{ resetForm })  =>  {
                        const data = {
                            ...values,
                            prototype: prototype
                        }
                            const response = await axios.post(process.env.REACT_APP_PROTOTYPE_CONTACT_ENDPOINT, data)
                            
                            if(response.data.success){
                                resetForm();
                                captchaRef.current?.reset()
                                swal("Enviado", "Los datos han sido enviados correctamente", "success");
                            }else{
                                if(response.data.error){
                                    swal("Error", "Ocurrio un problema al enviar el correo", "error");
                                }
                            }
                    }}
                    >
                    {({ errors, touched, handleSubmit,setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Field name="name" placeholder="Nombre"/>
                                {errors.name && touched.name ? (
                                    <div className='error'>{errors.name}</div>
                                ) : null}
                            <Field name="phone" type="number" placeholder="Teléfono"/>
                                {errors.phone && touched.phone ? (
                                    <div className='error'>{errors.phone}</div>
                                ) : null}
                            <Field name="email" type="email" placeholder="Correo"/>
                                {errors.email && touched.email ? <div className='error'>{errors.email}</div> : null}
                            <Field name="recaptcha">
                                {
                                    ({meta}) => {
                                        const handleChange = (value) => setFieldValue("recaptcha",value)
                                        return (
                                            <>
                                                
                                                <ReCAPTCHA
                                                    ref={captchaRef}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={handleChange}
                                                />
                                                { meta.error && meta.touched ? (
                                                    <div className='error'>{meta.error}</div>
                                                ) : null}
                                            
                                            </>)
                                    }
                                    
                                }
                            </Field>
                            <button className='button_send' type="submit">Enviar</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
     );
}
 
export default FormPrototype;