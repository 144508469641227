import React,{useState, useEffect,useCallback} from 'react';
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './Stiky.scss'
const Stiky = (props) => {
    const [y, setY] = useState(window.scrollY);
    const [showStiky, setShowStiky] = useState('ocultar')
    const [stikyOptions, setStikyOptions] = useState('')

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
              if(window.scrollY <= 550){
                setShowStiky('ocultar')
             }
          } else if (y < window.scrollY) {
              if(window.scrollY >= 550){
                setShowStiky('mostrar')
              }
          }
          setY(window.scrollY);
        },
        [y]
      );
      const scrollTarget = (target) => { 
        scroller.scrollTo(target, {smooth: true, duration: 700})
      };
      
      const scrollToPage =  (target) => {
        navigate(`/${target}`)
        scrollTarget(target.slice(1));
      };

    const handleMenu = (type) =>{
       if(type) scrollToPage(type)
    }
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
    
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

    useEffect(() => {
        if(pathname === '/casas-velasur'){
          setStikyOptions('not-galery')
        }else{
          setStikyOptions('')
        }
    },[pathname])
    return ( 
        <div className={`contenedor_stiky ${showStiky} ${stikyOptions}`}>
            <div className='brochure'>
                <p onClick={()=>{props.setModal(true)}}>BROCHURE</p>
            </div>
            <div className='disponibilidad'>
                <a onClick={()=> handleMenu('#masterplan')}>DISPONIBILIDAD</a>
            </div>
            <div className='galeria'>
                <Link to={'/galeria'}>GALERIA</Link>
            </div>
        </div>
     );
}
 
export default Stiky;