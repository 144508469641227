import React from "react";
import './SeparatorPrototype.scss'
const SeparatorPrototype = () => {
return(
<>
    <div id="prototipos" className="contenedor-prototipo">
        <div className="contenedor-prototipo-principal">
            <h2>
                PROTOTIPOS
            </h2>
            <p>
                Ubicadas en VELASUR dentro de PRIVADA ACACIA.
            </p>
            <div className="contenedor-textos">
                <p>CASAS VELASUR, están diseñadas para brindarte un estilo de vida único</p>
                <p>dentro de una comunidad residencial que lo tiene todo.</p>
            </div>
            <div className="contenedor-textos">
            <p> Cuídamos cada detalle, disfruta de</p>
            <p>los espacios pensados en ti y tu familia.</p>
            </div>
        </div>
        </div>
</>
);
}
export default SeparatorPrototype;