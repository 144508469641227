import React from "react";
import "./CasasVelasur.scss";
import SeparatorPrototype from "./SeparatorPrototypes/SeparatorPrototype";
import HeroCasas from "../HeroCasas/HeroCasas";
import Recorrido from "../Recorrido/Recorrido";
import AmenidadesDesc from "../AmenidadesDesc/AmenidadesDesc";
import PrototypeContainer from "../PrototypeContainer/PrototypeContainer";
const CasasVelasur = ({ setModalConfigProps }) => {
  return (
    <>
      <HeroCasas />
      <Recorrido />
      <SeparatorPrototype />
      <PrototypeContainer setModalConfigProps={setModalConfigProps} />
      <AmenidadesDesc />
    </>
  );
};
export default CasasVelasur;
