import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import './ConceptoVideo.scss'
const ConceptoVideo = (props) => {

    const handleVideo = () =>{
        props.setVideo(true)
        props.setUrlVideo("U1pR-yp3ajY")
    }
    return ( 
        <div id="concepto" className='contenedor_video'>
            <div className='contenedor_video__left'>
                <AnimationOnScroll
                    duration={2}
                    animateOnce={true}
                    animateIn='animate__fadeIn'>
                    <div className='contenedor_video__left_subtitulo'>
                        <h2>UNA NUEVA <br/> FORMA DE VIVIR</h2>
                    </div>
                </AnimationOnScroll>
                <div className='contenedor_video__left_texto'>
                    <p>VELASUR es una comunidad residencial diseñada para elevar el nivel de calidad de vida en espacios privados e inclusivos, rodeados de áreas naturales con diferentes ambientes para la recreación, el acondicionamiento físico y la convivencia familiar en contacto con la naturaleza.</p>
                </div>
                <div className='contenedor_video__left_button'>
                    <a href='/#masterplan'>VER DISPONIBILIDAD</a>
                </div>
            </div>
            <div className='contenedor_video__right'>
                <img className='play' src="/assets/Logos/boton-de-play.png" alt="" onClick={()=>handleVideo()}/>
                <div className='contenedor_video__right_video'>
                   {/* <img src="https://via.placeholder.com/993x559/" alt="" /> */}
                   <video src="/assets/Video/loop_velasur.mp4" autoPlay muted loop></video>
                </div>
            </div>
        </div>
     );
}
 
export default ConceptoVideo;